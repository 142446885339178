import { template as template_988c53b4789f4172a7eef050298a3e25 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_988c53b4789f4172a7eef050298a3e25(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
