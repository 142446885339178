import { template as template_772b32786451423ab419d1834a45e9b3 } from "@ember/template-compiler";
const EmptyState = template_772b32786451423ab419d1834a45e9b3(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
