import { template as template_36b45ca847de4be182361035d43d6e7a } from "@ember/template-compiler";
const FKText = template_36b45ca847de4be182361035d43d6e7a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
