import { template as template_902b0c37374349cf967ee8e9d46b8e3c } from "@ember/template-compiler";
const WelcomeHeader = template_902b0c37374349cf967ee8e9d46b8e3c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
