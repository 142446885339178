import { template as template_56826731e81a48dea3912b653813c0a3 } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllCategoriesSectionLink = template_56826731e81a48dea3912b653813c0a3(`
  <SectionLink
    @linkName="all-categories"
    @content={{i18n "sidebar.all_categories"}}
    @route="discovery.categories"
    @prefixType="icon"
    @prefixValue="sidebar.all_categories"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllCategoriesSectionLink;
