import { template as template_e37b6a7176674c90a56dccf07940d7a2 } from "@ember/template-compiler";
const FKLabel = template_e37b6a7176674c90a56dccf07940d7a2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
